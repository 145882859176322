<template>
  <div>

    <el-dialog
      width='500px'
      title='修改总监'
      :visible.sync='innerVisible'
      append-to-body>
      <div style='width: 90%;height: 200px;text-align: center;margin: auto;'>
        <div class='search-card'>
          <div style='display: flex;flex-direction: row;gap: 5px'>
            <UserSearch ref='refUserSearch' :keyword.sync='keyWord'
                        @selected='selectUser'></UserSearch>
            <el-button type='primary' @click='handleSelect'>确认</el-button>
          </div>
        </div>
        <div class='search-result'>
          <div style='width:200px;padding: 10px;text-align: center;margin: auto' v-if='currentObj.id'>
            {{ currentObj.nickname || '' }}
          </div>
        </div>

      </div>
    </el-dialog>
  </div>

</template>

<script>
import UserSearch from '@/pages/user/components/UserSearch'

export default {
  name: 'SelectManager',
  components: { UserSearch },
  data() {
    return {
      innerVisible: false,
      keyWord: '',
      currentObj: { id: null, nickname: null }
    }
  },
  methods: {
    show() {
      this.innerVisible = true
      this.keyWord = ''
      this.currentObj = {}
      // this.$refs['refUserSearch'].clear()
    },
    selectUser(val) {
      this.currentObj = val
    },
    handleSelect() {
      this.$emit('handleSelect', { user_id: this.currentObj.id, nickname: this.currentObj.nickname })
      this.innerVisible = false
    }
  }
}
</script>

<style scoped>
.search-card {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto;
  justify-content: center;
  align-items: center;
  /*border: #e97ca1 1px solid;*/
  border-radius: 20px;
}

.search-result {
  /*border: #e97ca1 1px dashed;*/
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, var(--primary), blue);
  /*背景剪辑*/
  /*background-clip: text;*/
  /*透明*/
  /*color: transparent;*/
  color: #f2f2f2;
  /*opacity: 0.8;*/
  font-size: 16px;
  border-radius: 4px;
}
</style>
